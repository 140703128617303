import React from 'react';
import { Layout, Typography, Row, Col, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;

const TermsAndConditions = () => {
  const sections = [
    {
      title: 'Introduction',
      content: `For the purposes of these Terms and Conditions, the terms "we," "us," "our" refer to Dance Hall by Shubhamani Chandrashekar Shetty, operating at Sanathana Natyalaya, SriDevi College Rd, Taripady, Lalbagh, Dakshina Kannada, Karnataka, 575003, India.

      The terms "you," "your," "user," "visitor" refer to any natural or legal person accessing our website or purchasing services from Dance Hall by Shubhamani Chandrashekar Shetty.

      Your use of this website and any purchase from Dance Hall by Shubhamani Chandrashekar Shetty are governed by the following Terms and Conditions:`
    },
    {
      title: 'Content Modifications',
      content: 'The content of this website is subject to change without notice.'
    },
    {
      title: 'No Warranty',
      content: 'Neither we nor any third parties provide any warranty or guarantee regarding the accuracy, timeliness, performance, completeness, or suitability of the information and materials on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.'
    },
    {
      title: 'Risk Assumption',
      content: 'Your use of any information or materials on this website is at your own risk, for which we shall not be liable. It is your responsibility to ensure that any products, services, or information available through this website meet your specific requirements.'
    },
    {
      title: 'Intellectual Property',
      content: 'The material on this website, including the design, layout, look, appearance, and graphics, is owned by or licensed to us. Reproduction is prohibited except in accordance with the copyright notice, which forms part of these Terms and Conditions.'
    },
    {
      title: 'Trademarks',
      content: 'All trademarks on this website that are not owned by, or licensed to, the operator are acknowledged on the website.'
    },
    {
      title: 'Unauthorized Use',
      content: 'Unauthorized use of the information provided by Dance Hall by Shubhamani Chandrashekar Shetty shall give rise to a claim for damages and may constitute a criminal offense.'
    },
    {
      title: 'Third-Party Links',
      content: 'From time to time, our website may include links to other websites for convenience. These links do not imply endorsement, and we are not responsible for the content of linked websites.'
    },
    {
      title: 'Prohibited Links',
      content: 'You may not create a link to this website from another website or document without Dance Hall by Shubhamani Chandrashekar Shetty\'s prior written consent.'
    },
    {
      title: 'Jurisdiction',
      content: 'Any dispute arising from the use of this website or purchases made from us is subject to the laws of India.'
    },
    {
      title: 'Payment Authorization',
      content: 'Dance Hall by Shubhamani Chandrashekar Shetty shall bear no liability for any loss or damage arising directly or indirectly from the decline of authorization for any transaction due to the cardholder exceeding the limit set by us and our acquiring bank from time to time.'
    }
  ];

  return (
    <Layout>
      <Content style={{ 
        background: 'linear-gradient(180deg, #E1F0FF 0%, #FFFFFF 100%)',
        minHeight: '100vh',
        padding: '24px'
      }}>
        <Row justify="center">
          <Col xs={24} sm={24} md={20} lg={16} style={{ 
            background: 'white',
            padding: '32px',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
          }}>
            <Link to="/" style={{ 
              display: 'inline-flex', 
              alignItems: 'center',
              marginBottom: '24px',
              color: '#1890ff'
            }}>
              <ArrowLeftOutlined style={{ marginRight: '8px' }} />
              Back to Home
            </Link>

            <Title level={1} style={{ 
              fontSize: '28px',
              marginBottom: '32px',
              textAlign: 'center'
            }}>
              Terms and Conditions
            </Title>

            {sections.map((section, index) => (
              <div key={index} style={{ marginBottom: '24px' }}>
                {section.title === 'Introduction' ? (
                  <Paragraph style={{ marginBottom: '24px', whiteSpace: 'pre-line' }}>
                    {section.content}
                  </Paragraph>
                ) : (
                  <>
                    <Title level={3} style={{ fontSize: '18px' }}>{section.title}</Title>
                    <Paragraph>{section.content}</Paragraph>
                  </>
                )}
              </div>
            ))}

            <Divider />

            <Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
              Last Updated: {new Date().toLocaleDateString('en-US', { 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric' 
              })}
            </Text>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default TermsAndConditions; 