import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import { App as AntProvider } from "antd";

import Auth0ProviderWithHistory from './components/Auth0Provider'; // Corrected path
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Auth0ProviderWithHistory>
        <AntProvider>
          <App />
        </AntProvider>
      </Auth0ProviderWithHistory>
    </Router>,
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
