import React, { useContext, useEffect,useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { RoleContext } from '../context/RoleContext';
import { Layout, Typography, Button, Row, Col, Card, Divider, Space } from 'antd';
import { 
  CalendarOutlined, 
  DollarOutlined, 
  LineChartOutlined, 
  ScheduleOutlined, 
  BookOutlined, 
  TrophyOutlined, 
  ReadOutlined,
  CheckOutlined,
  InstagramOutlined, 
  YoutubeOutlined 
} from '@ant-design/icons';
import { Collapse } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';


const { Panel } = Collapse;
const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

const Public = () => {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const { setRole } = useContext(RoleContext);
  const [emailVerified, setEmailVerified] = useState(false);

  const handleLogin = async (role) => {
    try {
      console.log(`Attempting to login as ${role}`);
      await loginWithRedirect({
        authorizationParams: { 
          redirect_uri: window.location.origin,
          screen_hint: 'signup', // Ensure the signup screen is shown
        },
        // appState: { returnTo: role === 'student' ? '/booking' : '/teacher', role },
        appState: { role },
      });
      console.log(`Redirecting to login as ${role}...`);
    } catch (error) {
      console.error(`Error during login with redirect as ${role}:`, error);
    }
  };
  const fetchRole = async (email) => {
    try {
      // Make sure we have a complete URL with protocol
      // const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      // const response = await fetch(`${apiUrl}/api/login`, {
         const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ email })
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Role data received:', data);
      return data.role;
    } catch (error) {
      console.error('Error fetching role:', error);
      return null;
    }
  };

  // Add FAQ data
const faqData = [
    {
      question: "What is DanceHall?",
      answer: "DanceHall is a web application designed to support teachers and students in the world of Indian classical dance. It offers tools for class management, payments, student tracking, and more."
    },
    {
      question: "How can I list my school?",
      answer: "To list your school, click on 'List my school' at the top of the page, sign up as a teacher, and follow the prompts to create and manage your school's profile."
    },
    {
      question: "How does payments work?",
      answer: "Payments are processed securely through our platform. Teachers can set fees, and students can pay directly through the website using secure payment gateways. All payments are tracked and visible in your dashboard."
    },
    {
      question: "How does cancellation work?",
      answer: "Our platform allows both teachers and students to cancel bookings within a specified timeframe. Refunds are processed automatically based on our cancellation policy."
    },
    {
      question: "How does payouts work?",
      answer: "Payouts to teachers are processed periodically. You can view your earnings and request withdrawals from your dashboard. Our platform ensures secure and timely payouts to your bank account."
    }
  ];

  useEffect(() => {
    console.log('useEffect called with isAuthenticated:', isAuthenticated);
    console.log('User object:', user);
    // if (isAuthenticated && user) {
    //   const storedAppState = JSON.parse(localStorage.getItem('appState'));
    //   if (storedAppState && storedAppState.role) {
    //     setRole(storedAppState.role);
    //     console.log('Role set from appState:', storedAppState.role);
    //     if (storedAppState.role === 'student') {
    //       console.log('Redirecting to /booking');
    //       navigate('/booking');
    //     } else if (storedAppState.role === 'teacher') {
    //       console.log('Redirecting to /teacher');
    //       navigate('/teacher');
    //     }
    //   }
    // }
    if(isAuthenticated && user)
    {
      if(user.email_verified){
        setEmailVerified(true);
        fetchRole(user.email).then(userRole=>{
        if(userRole){
          setRole(userRole);
          if(userRole==="student"){
            navigate('/booking');
          }else if(userRole==='teacher'){
            navigate('/teacher');
          }
        }
      });
      }
      else{
        setEmailVerified(false);
      }
    }
  }, [isAuthenticated, user, navigate, setRole]);
  if (isAuthenticated && !emailVerified){
    return (
      <Layout>
        <Content style={{ textAlign: 'center', padding: '50px' }}>
          <Title>Please verify your email to log in.</Title>
          <Text>Check your inbox for a verification email.</Text>
          <Text>after the verification, you can login again.</Text>
          <Button type="primary" onClick={() => logout({ returnTo: window.location.origin })}>
              Go back to login
            </Button>
        </Content>
      </Layout>
    )
  }
  // Update only the header section to include Auth0 functionality
  return (
    <Layout>
      <Header style={{ 
        background: '#fff', 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 16px',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        '@media (min-width: 768px)': {
          padding: '0 50px'
        }
      }}>
        <div className="logo" style={{ fontSize: '20px', fontWeight: 'bold' }}>DanceHall</div>
        <div style={{ display: 'flex', gap: '12px', alignItems: 'center', '@media (min-width: 768px)': { gap: '20px' } }}>
          <Button type="link">List my school</Button>
          {isAuthenticated ? (
            <Button type="primary" onClick={() => logout({ returnTo: window.location.origin })}>
              Logout
            </Button>
          ) : (
            <>
              <Button type="primary" onClick={() => handleLogin('teacher')}>Teacher</Button>
              <Button type="primary" onClick={() => handleLogin('student')}>Student</Button>
            </>
          )}
        </div>
      </Header>

      <Content>
        {/* Hero Section */}
        <Row justify="center" align="middle" style={{ 
          padding: '200px 16px',
          background: 'linear-gradient(180deg, #E1F0FF 0%, #F5F9FF 100%)', // Light blue to very light blue
          '@media (min-width: 768px)': {
            padding: '60px 50px'
          }
        }}>
          <Col xs={24} sm={24} md={16} style={{ textAlign: 'center' }}>
            <Title level={1} style={{ 
              fontSize: '50px',
              '@media (min-width: 768px)': {
                fontSize: '48px'
              }
            }}>
              Your Digital School for Indian Classical Dance
            </Title>
            <Text style={{ 
              fontSize: '20px',
              display: 'block',
              marginBottom: '24px',
              '@media (min-width: 768px)': {
                fontSize: '18px'
              }
            }}>
              A one-stop solution for teachers to build their online school – manage student progress, 
              payments, notifications, feedback, and learning resources all in one place.
            </Text>
            <Button type="primary" size="large">Join My DanceHall</Button>
          </Col>
        </Row>

        {/* Features Section */}
        <Row justify="center" style={{ 
          padding: '150px 16px', 
          background: 'linear-gradient(180deg, #F5F9FF 0%, #FFFFFF 100%)', // Very light blue to white
          '@media (min-width: 768px)': {
            padding: '40px 50px'
          }
        }}>
          <Col xs={24} sm={24} md={20}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Card 
                  title={<Title level={3}>For Teachers</Title>} 
                  bordered={false}
                  style={{ height: '100%' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <CalendarOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Manage Classes</Text>
                        <Text style={{ display: 'block' }}>Schedule and organize your classes with ease.</Text>
                      </div>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <DollarOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Manage Payments</Text>
                        <Text style={{ display: 'block' }}>Simplify payment tracking and collection.</Text>
                      </div>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <LineChartOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Track Student Progress</Text>
                        <Text style={{ display: 'block' }}>Monitor each student's growth and milestones.</Text>
                      </div>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <ScheduleOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Get Appointments</Text>
                        <Text style={{ display: 'block' }}>Schedule one-on-one appointments effortlessly.</Text>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              
              <Col xs={24} md={12}>
                <Card 
                  title={<Title level={3}>For Students</Title>} 
                  bordered={false}
                  style={{ height: '100%' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <BookOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Book Classes</Text>
                        <Text style={{ display: 'block' }}>Reserve your spot in classes with just a few clicks.</Text>
                      </div>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <TrophyOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Track Your Progress</Text>
                        <Text style={{ display: 'block' }}>Stay motivated by following your learning journey.</Text>
                      </div>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <ReadOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Access Teacher Materials</Text>
                        <Text style={{ display: 'block' }}>Enjoy exclusive access to learning materials shared by your teacher.</Text>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Pricing Section */}
<Row justify="center" style={{ 
  padding: '150px 16px', 
  background: '#fff',
  '@media (min-width: 768px)': {
    padding: '40px 50px'
  }
}}>
  <Col xs={24} sm={24} md={20}>
    <Title level={2} style={{ 
      textAlign: 'center', 
      margin: '30px 0',
      fontSize: '24px',
      '@media (min-width: 768px)': {
        margin: '60px 0 40px',
        fontSize: '32px'
      }
    }}>Plans</Title>
    <Row gutter={[16, 16]} justify="center">
      <Col xs={24} sm={24} md={8}>
        <Card 
          title={<Title level={3}>Basic</Title>}
          bordered={true}
          style={{ 
            height: '100%', 
            textAlign: 'center',
            marginBottom: '16px'
          }}
        >
          <Title level={4}>Starter Plan</Title>
          <Title level={2} style={{ margin: '24px 0' }}>799 INR<Text style={{ fontSize: '16px' }}>/month</Text></Title>
          <ul style={{ 
            listStyle: 'none', 
            padding: 0, 
            textAlign: 'left',
            margin: '0 auto',
            maxWidth: '280px'
          }}>
            <li style={{ margin: '16px 0' }}>
              <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
              Free one month
            </li>
            <li style={{ margin: '16px 0' }}>
              <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
              3.5% booking fee
            </li>
            <li style={{ margin: '16px 0' }}>
              <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
              Up to 4 students
            </li>
          </ul>
          <Button type="primary" size="large" style={{ 
            marginTop: '24px',
            width: '100%',
            '@media (min-width: 768px)': {
              width: 'auto'
            }
          }}>Get Started</Button>
        </Card>
      </Col>

      <Col xs={24} sm={24} md={8}>
        <Card 
          title={<Title level={3}>Pro</Title>}
          bordered={true}
          style={{ 
            height: '100%', 
            textAlign: 'center',
            marginBottom: '16px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
          }}
        >
          <Title level={4}>Professional Plan</Title>
          <Title level={2} style={{ margin: '24px 0' }}>2399 INR<Text style={{ fontSize: '16px' }}>/month</Text></Title>
          <ul style={{ 
            listStyle: 'none', 
            padding: 0, 
            textAlign: 'left',
            margin: '0 auto',
            maxWidth: '280px'
          }}>
            <li style={{ margin: '16px 0' }}>
              <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
              Free one month
            </li>
            <li style={{ margin: '16px 0' }}>
              <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
              3.5% booking fee
            </li>
            <li style={{ margin: '16px 0' }}>
              <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
              Up to 10 students
            </li>
          </ul>
          <Button type="primary" size="large" style={{ 
            marginTop: '24px',
            width: '100%',
            '@media (min-width: 768px)': {
              width: 'auto'
            }
          }}>Get Started</Button>
        </Card>
      </Col>

      <Col xs={24} sm={24} md={8}>
        <Card 
          title={<Title level={3}>Ultimate</Title>}
          bordered={true}
          style={{ 
            height: '100%', 
            textAlign: 'center',
            marginBottom: '16px'
          }}
        >
          <Title level={4}>Enterprise Plan</Title>
          <Title level={2} style={{ margin: '24px 0' }}>3999 INR<Text style={{ fontSize: '16px' }}>/month</Text></Title>
          <ul style={{ 
            listStyle: 'none', 
            padding: 0, 
            textAlign: 'left',
            margin: '0 auto',
            maxWidth: '280px'
          }}>
            <li style={{ margin: '16px 0' }}>
              <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
              Free one month
            </li>
            <li style={{ margin: '16px 0' }}>
              <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
              No booking fee
            </li>
            <li style={{ margin: '16px 0' }}>
              <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
              Up to 25 students
            </li>
          </ul>
          <Button type="primary" size="large" style={{ 
            marginTop: '24px',
            width: '100%',
            '@media (min-width: 768px)': {
              width: 'auto'
            }
          }}>Get Started</Button>
        </Card>
      </Col>
    </Row>
  </Col>
</Row>


<Row justify="center" style={{ 
  padding: '40px 16px', 
  background: 'linear-gradient(180deg, #FFFFFF 0%, #F5F9FF 100%)', // Subtle gradient background
  '@media (min-width: 768px)': {
    padding: '60px 50px'
  }
}}>
  <Col xs={24} sm={24} md={20}>
    <Title level={2} style={{ 
      textAlign: 'center', 
      margin: '30px 0',
      fontSize: '24px',
      color: '#1A1A1A',
      '@media (min-width: 768px)': {
        margin: '60px 0 40px',
        fontSize: '32px'
      }
    }}>Frequently Asked Questions</Title>
    
    <Collapse 
      bordered={false}
      expandIconPosition="end"
      style={{ 
        background: 'transparent'
      }}
    >
      {faqData.map((faq, index) => (
        <Panel 
          header={
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <QuestionCircleOutlined style={{ 
                color: '#1890ff',
                fontSize: '20px'
              }} />
              <Text strong style={{ 
                fontSize: '16px',
                color: '#2A2A2A',
                '@media (min-width: 768px)': {
                  fontSize: '18px'
                }
              }}>
                {faq.question}
              </Text>
            </div>
          }
          key={index}
          style={{ 
            marginBottom: '16px',
            background: 'white',
            borderRadius: '8px',
            border: '1px solid #f0f0f0',
            boxShadow: '0 2px 8px rgba(0,0,0,0.04)',
            transition: 'all 0.3s ease',
            '&:hover': {
              boxShadow: '0 4px 12px rgba(0,0,0,0.08)'
            }
          }}
        >
          <div style={{
            padding: '8px 4px',
            color: '#595959',
            fontSize: '14px',
            lineHeight: '1.6',
            '@media (min-width: 768px)': {
              fontSize: '16px',
              padding: '12px 8px'
            }
          }}>
            {faq.answer}
          </div>
        </Panel>
      ))}
    </Collapse>

    {/* Optional: Add a contact support section */}
    <Row justify="center" style={{ marginTop: '40px' }}>
      <Col style={{ textAlign: 'center' }}>
        <Text type="secondary" style={{ 
          display: 'block',
          marginBottom: '16px',
          fontSize: '14px',
          '@media (min-width: 768px)': {
            fontSize: '16px'
          }
        }}>
          Still have questions?
        </Text>
        <Button type="primary" size="large">
          Contact Support
        </Button>
      </Col>
    </Row>
  </Col>
</Row>

<Footer style={{ 
  background: '#001529',
  padding: '40px 16px 24px',
  color: 'rgba(255, 255, 255, 0.85)',
  '@media (min-width: 768px)': {
    padding: '60px 50px 24px'
  }
}}>
  <Row justify="space-between" gutter={[32, 32]} style={{ textAlign: 'left' }}>
    {/* Company Section */}
    <Col xs={24} sm={12} md={6}>
      <Title level={3} style={{ 
        marginBottom: '24px', 
        textAlign: 'left',
        fontSize: '20px',
        color: 'white',
        '@media (min-width: 768px)': {
          fontSize: '24px'
        }
      }}>Company</Title>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: '16px'
      }}>
        {/* <Button type="link" style={{ 
          padding: 0, 
          height: 'auto', 
          color: 'rgba(255, 255, 255, 0.85)',
          textAlign: 'left', 
          justifyContent: 'flex-start',
          fontSize: '14px',
          '@media (min-width: 768px)': {
            fontSize: '16px'
          }
        }}>About Us</Button> */}
        <Button 
          type="link" 
          onClick={() => navigate('/terms')} 
          style={{ 
            padding: 0, 
            height: 'auto', 
            color: 'rgba(255, 255, 255, 0.85)', 
            textAlign: 'left', 
            justifyContent: 'flex-start' 
          }}
        >
          Terms & Conditions
        </Button>
        <Button 
          type="link" 
          onClick={() => navigate('/privacy-policy')} 
          style={{ 
            padding: 0, 
            height: 'auto', 
            color: 'rgba(255, 255, 255, 0.85)', 
            textAlign: 'left', 
            justifyContent: 'flex-start' 
          }}
        >
          Privacy Policy
        </Button>
        <Button 
          type="link" 
          onClick={() => navigate('/refund-policy')} 
          style={{ 
            padding: 0, 
            height: 'auto', 
            color: 'rgba(255, 255, 255, 0.85)', 
            textAlign: 'left', 
            justifyContent: 'flex-start' 
          }}
        >
          Refund & Cancellation Policy
        </Button>
      </div>
    </Col>

    {/* Support Section */}
    <Col xs={24} sm={12} md={6}>
      <Title level={3} style={{ 
        marginBottom: '24px', 
        textAlign: 'left',
        fontSize: '20px',
        color: 'white',
        '@media (min-width: 768px)': {
          fontSize: '24px'
        }
      }}>Support</Title>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Button type="link" style={{ padding: 0, height: 'auto', color: 'rgba(255, 255, 255, 0.85)', textAlign: 'left', justifyContent: 'flex-start' }}>Contact Us</Button>
        <Button type="link" style={{ padding: 0, height: 'auto', color: 'rgba(255, 255, 255, 0.85)', textAlign: 'left', justifyContent: 'flex-start' }}>support@mydancehall.com</Button>
      </div>
    </Col>

    {/* Contact Section */}
    <Col xs={24} sm={12} md={6}>
      <Title level={3} style={{ 
        marginBottom: '24px', 
        textAlign: 'left',
        fontSize: '20px',
        color: 'white',
        '@media (min-width: 768px)': {
          fontSize: '24px'
        }
      }}>Contact</Title>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: '16px',
        fontSize: '14px',
        color: 'rgba(255, 255, 255, 0.85)',
        '@media (min-width: 768px)': {
          fontSize: '16px'
        }
      }}>
        <Text style={{ color: 'rgba(255, 255, 255, 0.85)' }}>Sanathana Natyalaya</Text>
        <Text style={{ color: 'rgba(255, 255, 255, 0.85)' }}>SriDevi College Rd, Taripady, Lalbagh, Dakshina Kannada</Text>
        
        <Text style={{ color: 'rgba(255, 255, 255, 0.85)' }}>Karnataka, 575003</Text>
        <Text style={{ color: 'rgba(255, 255, 255, 0.85)' }}>India</Text>
        
      </div>
    </Col>

    {/* Follow Us Section */}
    <Col xs={24} sm={12} md={6}>
      <Title level={3} style={{ 
        marginBottom: '24px', 
        textAlign: 'left',
        fontSize: '20px',
        color: 'white',
        '@media (min-width: 768px)': {
          fontSize: '24px'
        }
      }}>Follow Us</Title>
      <Space size="large" style={{ textAlign: 'left' }}>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <InstagramOutlined style={{ 
            fontSize: '24px', 
            color: 'rgba(255, 255, 255, 0.85)',
            '@media (min-width: 768px)': {
              fontSize: '28px'
            }
          }} />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
          <YoutubeOutlined style={{ 
            fontSize: '24px', 
            color: 'rgba(255, 255, 255, 0.85)',
            '@media (min-width: 768px)': {
              fontSize: '28px'
            }
          }} />
        </a>
      </Space>
    </Col>
  </Row>

  {/* Copyright Section */}
  <Row justify="center" style={{ 
    marginTop: '40px',
    '@media (min-width: 768px)': {
      marginTop: '60px'
    }
  }}>
    <Col>
      <Divider style={{ margin: '24px 0', borderColor: 'rgba(255, 255, 255, 0.15)' }} />
      <Text style={{ 
        color: 'rgba(255, 255, 255, 0.45)',
        fontSize: '14px',
        '@media (min-width: 768px)': {
          fontSize: '16px'
        }
      }}>
        DanceHall ©2024 All Rights Reserved
      </Text>
    </Col>
  </Row>
</Footer>
</Content>
      
    </Layout>
  );
};

export default Public;