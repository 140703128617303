import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, Row, Col, Table, Space, Modal, message, Form, Input, InputNumber, Typography } from 'antd';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import Confirmation from './confirmation';
import { useNavigate, useLocation } from 'react-router-dom';
import { DashboardOutlined, CalendarOutlined, HistoryOutlined } from '@ant-design/icons';

const { Header, Sider, Content, Footer } = Layout;
const { Title } = Typography;

const Booking = () => {
  const { logout, user, } = useAuth0();
  const { nickname: userName, email: userEmail } = user;
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [date, setDate] = useState(new Date());
  const [activeView, setActiveView] = useState('dashboard');
  const [weeklySlots, setWeeklySlots] = useState({});
  const [displayedDays, setDisplayedDays] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState({});
  const [boundaryDate, setBoundaryDate] = useState(null);
  const [currency, setCurrency] = useState('USD'); // Currency for selected slot
  const [paymentAmount, setPaymentAmount] = useState(0); // Amount to be paid
  const [showModal, setShowModal] = useState(false); // Controls modal visibility
  const [errors, setErrors] = useState({}); // Error messages for form validation
  const [name, setName] = useState(userName ?? ''); // Student name
  const [email, setEmail] = useState(userEmail ?? ''); // Student email
  const [phone, setPhone] = useState(''); // Student phone number
  const [activeBookings, setActiveBookings] = useState([]);
  const [historyBookings, setHistoryBookings] = useState([]);
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [couponCode, setCouponCode] = useState('');
  const [discountedAmount, setDiscountedAmount] = useState(null);
  const [couponError, setCouponError] = useState('');
  const [discountPercent, setDiscountPercent] = useState(0);
  const [isBookingConfirmed, setIsBookingConfirmed] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [cancellingId, setCancellingId] = useState(null);

  // Add simple hash navigation for dashboard
  useEffect(() => {
    if (!location.hash) {
      navigate('/booking#dashboard', { replace: true });
    }
  }, []);

  // Sidebar items
  const menuItems = [
    {
      key: '1',
      label: 'Dashboard',
      onClick: () => navigate('/booking#dashboard'),
      icon: <DashboardOutlined />,
    },
    { 
      key: '2', 
      label: 'Active Classes',
      onClick: () => navigate('/booking#activeclasses'),
      icon: <CalendarOutlined />,
    },
    { 
      key: '3', 
      label: 'History',
      onClick: () => navigate('/booking#history'),
      icon: <HistoryOutlined />,
    },
    { 
      key: '4', 
      label: 'Contact Us',
      onClick: () => setActiveView('contactUs')
    },
    { 
      key: '5', 
      label: 'Terms & Conditions',
      onClick: () => setActiveView('termsConditions')
    },
    { 
      key: '6', 
      label: 'Logout', 
      onClick: () => logout({ returnTo: window.location.origin }) 
    },
  ];



  const handleSlotSelect = (slot) => {
    console.log('Slot selected:', slot);
    console.log('Current currency:', currency);
    console.log('Current payment amount:', paymentAmount);

    // Define updatedSlot here before using it
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('User timezone:', localTimeZone);

    // Set currency and amount based on timezone
  let slotCurrency, slotAmount;
  if (localTimeZone.includes('Asia/')) {
    console.log('Setting Indian currency and amount');
    slotCurrency = 'INR';
    slotAmount = 1000;
  } else {
    console.log('Setting US currency and amount');
    slotCurrency = 'USD';
    slotAmount = 25;
  }

  const updatedSlot = {
    ...slot,
    currency: slotCurrency,
    paymentAmount: slotAmount
  };

  setSelectedSlot(updatedSlot);
  setCurrency(slotCurrency);
  setPaymentAmount(slotAmount);
  setShowModal(true);

  console.log('Updated slot with currency and amount:', updatedSlot);
};

  // Fetch slots once and cache them
  const fetchWeeklySlots = async () => {
    try {
      console.log('Fetching slots...');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/slots`);
      const allSlots = await response.json();
      console.log('Raw slots from API:', allSlots);

      const slotsByDay = {};
      const today = dayjs();

      // Only process slots that are in the future
      allSlots.forEach(slot => {
        const slotDate = dayjs(slot.dateTime);
        if (slotDate.isAfter(today)) {
          const dayKey = slotDate.format('YYYY-MM-DD');
          if (!slotsByDay[dayKey]) {
            slotsByDay[dayKey] = [];
          }
          slotsByDay[dayKey].push({
            ...slot,
            formattedTime: slotDate.format('h:mm A')
          });
        }
      });

      // Sort slots within each day
      Object.keys(slotsByDay).forEach(day => {
        slotsByDay[day].sort((a, b) => 
          dayjs(a.dateTime).diff(dayjs(b.dateTime))
        );
      });

      console.log('Organized slots by day:', slotsByDay);
      setWeeklySlots(slotsByDay);
    } catch (err) {
      console.error('Failed to fetch slots', err);
    }
  };

  useEffect(() => {
    fetchWeeklySlots();
  }, []);

  // Generate next 5 days starting from `startDate`, but include current day as unavailable
  const generateNextFiveDays = (startDate) => {
    const days = [];
    for (let i = 0; i < 5; i++) {
      days.push(dayjs(startDate).add(i, 'day').format('YYYY-MM-DD'));
    }
    return days;
  };

  // Handle boundary-based date updates
  const handleDateSelect = (selectedDate) => {
    setDate(selectedDate);

    const newDisplayedDays = generateNextFiveDays(selectedDate);
    setDisplayedDays(newDisplayedDays);
    setBoundaryDate(dayjs(newDisplayedDays[newDisplayedDays.length - 1]));

    const newFilteredSlots = {};
    newDisplayedDays.forEach(day => {
      newFilteredSlots[day] = weeklySlots[day] || [];
    });
    setFilteredSlots(newFilteredSlots);
  };

  useEffect(() => {
    const initialDisplayedDays = generateNextFiveDays(date);
    setDisplayedDays(initialDisplayedDays);
    setBoundaryDate(dayjs(initialDisplayedDays[initialDisplayedDays.length - 1]));

    const slotsForInitialRange = {};
    initialDisplayedDays.forEach(day => {
      slotsForInitialRange[day] = weeklySlots[day] || [];
    });
    setFilteredSlots(slotsForInitialRange);
  }, [weeklySlots]);

  const checkOrCreateStudentProfile = async () => {
    try {
      console.log('Checking/creating student profile for:', { name, email });
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/check-or-create-student`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          email: email,
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.text();
        console.error('Failed to check/create profile. Server response:', errorData);
        throw new Error('Failed to create student profile');
      }
      
      const data = await response.json();
      console.log('Profile check/create response:', data);
      return data.success;
    } catch (err) {
      console.error('Error in checkOrCreateStudentProfile:', err);
      return false;
    }
  };


  useEffect(() => {
    if (user) {
      checkOrCreateStudentProfile();
    }
  }, [user]);


  const handleCouponApply = async () => {
    setCouponError('');
    setDiscountedAmount(null);
    setDiscountPercent(0);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/coupons/validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: couponCode })
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        setCouponError(data.error);
        return;
      }
  
      const discount = (paymentAmount * data.discount) / 100;
      const finalAmount = paymentAmount - discount;
      setDiscountedAmount(finalAmount);
      setDiscountPercent(data.discount);
    } catch (error) {
      setCouponError('Error validating coupon');
      console.error('Coupon validation error:', error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('1. Payment form submission started');

    if (!selectedSlot) {
      console.log('2. Error: No slot selected');
      setErrors({ form: 'Please select a class slot' });
      return;
    }

    try {
      const finalAmount = discountedAmount || paymentAmount;
      console.log('3. Processing payment with amount:', finalAmount);

      // If there's a valid coupon, apply it first
      if (discountedAmount && couponCode) {
        console.log('4. Applying coupon:', couponCode);
        const couponResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/coupons/apply`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code: couponCode })
        });

        if (!couponResponse.ok) {
          console.log('5. Coupon application failed');
          throw new Error('Failed to apply coupon');
        }

        const couponResult = await couponResponse.json();
        console.log('6. Coupon successfully applied:', couponResult);
      }

      // Ensure profile exists before proceeding
      console.log('7. Checking/creating student profile');
      const profileCreated = await checkOrCreateStudentProfile();
      if (!profileCreated) {
        console.log('8. Error: Profile creation failed');
        throw new Error('Unable to create/verify student profile');
      }
      console.log('9. Profile check/creation successful');

      // console.log('Submitting booking for slot:', selectedSlot);
      // console.log('PAYLOAD', {
      //     slotId: selectedSlot._id,
      //     studentName: name,
      //     studentEmail: email,
      //     studentPhone: phone,
      //     paymentAmount: parseInt(paymentAmount),
      //     currency: selectedSlot.currency,
      //   })

      // If there's a valid coupon, apply it
      if (discountedAmount && couponCode) {
        await fetch(`${process.env.REACT_APP_API_URL}/api/coupons/apply`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code: couponCode })
        });
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/create-booking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          slotId: selectedSlot._id,
          studentName: name,
          studentEmail: email,
          studentPhone: phone,
          paymentAmount: parseInt(finalAmount),
          currency,
          couponApplied: discountedAmount ? couponCode : null,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Booking failed:', errorText);
        throw new Error(errorText);
      }

      const data = await response.json();
      console.log('Booking successful:', data);

      // Fetch updated slots
      await fetchWeeklySlots();

      // Initialize Razorpay and handle payment
      const options = {
        amount: data.amount, // Amount in smallest currency unit
        currency: data.currency,
        name: 'Class Booking',
        description: 'Payment for class booking',
        order_id: data.orderId, // Razorpay order ID
        handler: async (response) => {
          console.log('15. Razorpay payment successful:', response);

          try {
            console.log('16. Sending payment verification to server');
            const successResponse = await fetch(`${process.env.REACT_APP_API_URL}/payment-success`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              }),
            });

            if (!successResponse.ok) {
              const errorText = await successResponse.text();
              console.log('17. Payment verification failed:', errorText);
              throw new Error(errorText);
            }

            const successData = await successResponse.json();
            console.log('18. Payment verification response:', successData);

            // Add confirmation handling here
            const confirmationDetails = {
              bookingId: successData.booking.bookingId,
              name: name,
              email: email,
              dateTime: selectedSlot.dateTime,
              amount: finalAmount,
              currency: currency,
              googleMeetLink: successData.booking.googleMeetLink || 'https://meet.google.com/czy-ihvn-rho'
            };
            console.log('19. Setting confirmation details:', confirmationDetails);
            
            setConfirmationDetails(confirmationDetails);
            setIsBookingConfirmed(true);
            setShowModal(false);
            
            console.log('20. Confirmation states updated');
            console.log('isBookingConfirmed:', true);
            console.log('confirmationDetails:', confirmationDetails);

            await fetchWeeklySlots();
            console.log('21. Slots updated after successful payment');
          } catch (err) {
            console.error('Error in payment success handler:', err);
            setErrors({ form: `Failed to update slot status: ${err.message}` });
          }
        },
        prefill: {
          name: name,
          email: email,
          contact: phone,
        },
        theme: {
          color: '#3399cc',
        },
      };


      const rzp = new window.Razorpay(options);
      rzp.on('payment.failed', function (response) {
        console.error('Payment failed:', response.error);
        setErrors({ form: `Payment failed: ${response.error.description}` });
      });
      rzp.open();
    } catch (err) {
      console.error('Error creating booking:', err);
      setErrors({ form: `Failed to create booking: ${err.message}` });
    }
};

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  useEffect(() => {
    if (activeView === 'activeClasses' || activeView === 'history') {
      const fetchBookings = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/student/${user.email}/bookings`);
          const data = await response.json();
          const currentDate = new Date();
          
          // Split bookings into active and history
          const active = data.filter(booking => new Date(booking.slotId?.dateTime) > currentDate);
          const history = data.filter(booking => new Date(booking.slotId?.dateTime) <= currentDate);
          
          setActiveBookings(active);
          setHistoryBookings(history);
        } catch (err) {
          console.error('Failed to fetch bookings', err);
        }
      };
      fetchBookings();
    }
  }, [activeView, user.email]);

  const handleCancelBooking = (id) => {
    Modal.confirm({
      title: 'Cancel Booking',
      content: 'Are you sure you want to cancel this booking?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => onCancel(id)
    });
  };

  const onCancel = async (id) => {
    setCancellingId(id);
    try {
      // First update the UI optimistically
      setActiveBookings(prevBookings => 
        prevBookings.map(booking => 
          booking._id === id 
            ? { ...booking, bookingStatus: 'cancelled' }  // Only update booking status
            : booking
        )
      );

      // Then make the API call
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/cancel-booking/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to cancel booking');
      }

      // Refresh the bookings to get the latest state
      const updatedResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/student/${user.email}/bookings`);
      const data = await response.json();
      const currentDate = new Date();
      
      // Update active bookings while preserving payment status
      setActiveBookings(data.filter(booking => 
        new Date(booking.slotId?.dateTime) > currentDate
      ));

    } catch (err) {
      console.error('Error canceling booking:', err);
      // Revert the optimistic update if the API call failed
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/student/${user.email}/bookings`);
      const data = await response.json();
      const currentDate = new Date();
      setActiveBookings(data.filter(booking => 
        new Date(booking.slotId?.dateTime) > currentDate
      ));
    } finally {
      setCancellingId(null);
    }
  };

  const activeClassesColumns = [
    {
      title: 'Class Date',
      dataIndex: 'classDate',
      key: 'classDate',
    },
    {
      title: 'Class Time',
      dataIndex: 'classTime',
      key: 'classTime',
    },
    {
      title: 'Google Meet Link',
      dataIndex: 'googleMeetLink',
      key: 'googleMeetLink',
      render: (link) => (
        <a href={link} target="_blank" rel="noopener noreferrer">
          Join
        </a>
      ),
    },
    {
      title: 'Class Cost',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (status) => status === 'paid' ? 'Paid' : 'Pending'
    },
    {
      title: 'Booking Status',
      dataIndex: 'bookingStatus',
      key: 'bookingStatus',
      render: (status) => status === 'cancelled' ? 'Cancelled' : 'Active'
    },
    {
      title: 'Booking Date',
      dataIndex: 'bookingDate',
      key: 'bookingDate',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button 
            loading={cancellingId === record.id}
            onClick={() => handleCancelBooking(record.id)}
            disabled={record.bookingStatus === 'cancelled'}
          >
            Cancel
          </Button>
        </Space>
      ),
    },
  ];

  const historyClassesColumns = [
    {
      title: 'Class Date',
      dataIndex: 'classDate',
      key: 'classDate',
    },
    {
      title: 'Class Time',
      dataIndex: 'classTime',
      key: 'classTime',
    },
    {
      title: 'Class Cost',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
    },
  ];

  useEffect(() => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (localTimeZone.includes('Asia/')) {
      setCurrency('INR');
      setPaymentAmount(1000);
    } else {
      setCurrency('USD');
      setPaymentAmount(25);
    }
  }, []); // Empty dependency array means this runs once when component mounts

  const handlePaymentSuccess = async (response) => {
    console.log('15. Razorpay payment successful:', response);
    try {
      const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
      
      const verifyResponse = await fetch(`${process.env.REACT_APP_API_URL}/payment-success`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          order_id: razorpay_order_id,
          payment_id: razorpay_payment_id,
          signature: razorpay_signature
        })
      });

      const data = await verifyResponse.json();
      console.log('Payment verification response:', data);

      if (verifyResponse.ok && data.success) {
        console.log('18. Payment verified successfully');
        const confirmationDetails = {
          bookingId: data.booking.bookingId,
          name: name,
          email: email,
          dateTime: data.booking.dateTime,
          amount: data.booking.amount,
          currency: data.booking.currency,
          googleMeetLink: data.booking.googleMeetLink || 'https://meet.google.com/czy-ihvn-rho'
        };
        
        // Navigate to confirmation with state
        navigate('/confirmation', { 
          state: { confirmationDetails }
        });
      } else {
        throw new Error('Payment verification failed');
      }
    } catch (error) {
      console.error('Payment verification error:', error);
      setErrors({ payment: 'Payment verification failed' });
    }
  };

  useEffect(() => {
    if (!location.hash) {
      navigate('/booking#dashboard');
    } else if (location.hash === '#activeclasses') {
      setActiveView('activeClasses');
    } else if (location.hash === '#dashboard') {
      setActiveView('dashboard');
    } else if (location.hash === '#history') {
      setActiveView('history');
    }
  }, [location.hash, navigate]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header 
          style={{ 
            color: 'white', 
            fontSize: '1.5em', 
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <span>Welcome to Mani's DanceHall, {userName}</span>
        </Header>
      <Layout>
      <Sider width={200}>
        <Menu 
          mode="inline" 
          defaultSelectedKeys={[
            location.hash === '#activeclasses' ? '2' : 
            location.hash === '#history' ? '3' : '1'
          ]} 
          style={{ height: '100%' }} 
          items={menuItems} 
        />
      </Sider>
        <Content style={{ padding: '0 24px', minHeight: 280 }}>
          {isBookingConfirmed && confirmationDetails ? (
            <Confirmation bookingDetails={confirmationDetails} />
          ) : (
            <>
              {activeView === 'dashboard' && (
                <div className="dashboard-content">
                <div className="calendar-section" style={{ marginTop: '24px' }}>
                  <Title level={2} style={{ marginBottom: '16px' }}>Select a date</Title>
                  <Calendar onChange={handleDateSelect} value={date} minDate={new Date()} locale="en-US" />
                </div>
                  <div className="available-classes">
                    <h2>Available Classes</h2>
                      <div className="class-list-body">
                        {/* Display the slots for each day */}
                        <Row gutter={[16, 16]}>
                          {displayedDays.map((day) => (
                            <Col span={4} key={day}>
                              <h3 style={{ textAlign: 'center' }}>{dayjs(day).format('ddd, MMM DD')}</h3>
                              {filteredSlots[day] && filteredSlots[day].length > 0 ? (
                                <ul style={{ padding: 0, listStyleType: 'none' }}>
                                  {filteredSlots[day].map((slot) => (
                                    <li key={slot._id} style={{ marginBottom: '8px' }}>
                                      <Button
                                        className={selectedSlot && selectedSlot._id === slot._id ? 'selected' : ''}
                                        style={{ width: '100%' }}
                                        onClick={() => handleSlotSelect(slot)}
                                      >
                                        {dayjs(slot.dateTime).format('h:mm A')}
                                        {/* <br /> */}
                                        {/* {slot.currency} {slot.paymentAmount} */}
                                      </Button>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p style={{ color: 'gray', textAlign: 'center' }}>No slots</p>
                              )}
                            </Col>
                          ))}
                        </Row>
                      </div>
                    
                  </div>
                </div>
              )}
              {activeView === 'activeClasses' && (
                <div style={{ padding: '24px' }}>
                  <h2>Your Active Bookings</h2>
                  <Table
                    columns={activeClassesColumns}
                    dataSource={activeBookings.map(booking => ({
                      key: booking._id,
                      id: booking._id,
                      classDate: formatDate(new Date(booking.slotId?.dateTime)),
                      classTime: new Date(booking.slotId?.dateTime).toLocaleTimeString('en-US', { timeZone: localTimeZone }),
                      googleMeetLink: booking.googleMeetLink,
                      paymentAmount: booking.paymentAmount,
                      paymentStatus: booking.paymentStatus,
                      bookingStatus: booking.bookingStatus,
                      bookingDate: formatDate(new Date(booking.createdAt))
                    }))}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              )}
              {activeView === 'history' && (
                <div style={{ padding: '24px' }}>
                  <h2>History Bookings</h2>
                  <Table
                    columns={historyClassesColumns}
                    dataSource={historyBookings.map(booking => ({
                      key: booking._id,
                      id: booking._id,
                      classDate: formatDate(new Date(booking.slotId?.dateTime)),
                      classTime: new Date(booking.slotId?.dateTime).toLocaleTimeString('en-US', { timeZone: localTimeZone }),
                      paymentAmount: booking.paymentAmount,
                      paymentStatus: booking.paymentStatus,
                    }))}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              )}
            </>
          )}
        </Content>
      </Layout>
      <Footer style={{ textAlign: 'center' }}>My Dance Hall ©{new Date().getFullYear()}</Footer>

      {/* Payment Form Modal */}
       <Modal 
         open={showModal} 
         title="Payment Form" 
         onCancel={() => {
           setShowModal(false);
           setCouponCode('');
           setDiscountedAmount(null);
           setCouponError('');
         }} 
         onOk={handleSubmit}
         okText="Pay"
       >
         <Form name="paymentForm" initialValues={{ name, phone, email }}>
           <Form.Item
             label="Name"
             name="name"
           >
             <Input 
               value={name} 
               disabled
               style={{ backgroundColor: '#f5f5f5' }}
             />
           </Form.Item>
           
           <Form.Item
             label="Email"
             name="email"
           >
             <Input 
               value={email} 
               disabled
               style={{ backgroundColor: '#f5f5f5' }}
             />
           </Form.Item>

           <Form.Item
             label="Phone"
             name="phone"
             rules={[
               {
                 required: true,
                 message: "Phone is a required field"
               }
             ]}
           >
             <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
           </Form.Item>
           <Form.Item label="Coupon Code">
             <Input.Group compact>
               <Input 
                 style={{ width: '70%' }} 
                 value={couponCode}
                 onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
                 placeholder="Enter coupon code"
               />
               <Button 
                 style={{ width: '30%' }} 
                 type="primary"
                 onClick={handleCouponApply}
               >
                 Apply
               </Button>
             </Input.Group>
             {couponError && (
               <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
                 {couponError}
               </div>
             )}
           </Form.Item>
           <Form.Item label="Payment Amount">
             <Input 
               value={`${currency} ${discountedAmount || paymentAmount}`}
               disabled
               style={{ width: '100%' }}
             />
             {discountedAmount && (
               <div style={{ color: 'green', fontSize: '12px', marginTop: '4px' }}>
                 {discountPercent}% discount applied! Original price: {currency} {paymentAmount}
               </div>
             )}
           </Form.Item>
         </Form>
       </Modal>
    </Layout>
  );


};

export default Booking;
