   // client/src/components/confirmation.js
   import React from 'react';
   import { Button, Space, Card, Typography } from 'antd';
   import { useNavigate } from 'react-router-dom';
   import { GoogleOutlined, WindowsOutlined } from '@ant-design/icons';
   import './Confirmation.css';

   const { Title, Text } = Typography;

   const Confirmation = ({ bookingDetails }) => {
     const navigate = useNavigate();

     const formatDateTime = (dateTimeStr) => {
       try {
         const date = new Date(dateTimeStr);
         
         // Get user's timezone
         const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
         
         return date.toLocaleString('en-US', {
           weekday: 'long',
           month: 'long',
           day: 'numeric',
           year: 'numeric',
           hour: '2-digit',
           minute: '2-digit',
           hour12: true,
           timeZone: timeZone,
           timeZoneName: 'short' // This will add timezone abbreviation (e.g., EST)
         });
       } catch (error) {
         console.error('Error formatting date:', error, dateTimeStr);
         return 'Invalid Date';
       }
     };

     return (
       <Card className="confirmation-card">
         <Title level={2} className="confirmation-title">
           Booking Confirmation
         </Title>

         <div className="confirmation-details">
           <div className="detail-item">
             <Text strong>Name:</Text>
             <Text>{bookingDetails.name}</Text>
           </div>

           <div className="detail-item">
             <Text strong>Booking ID:</Text>
             <Text>{bookingDetails.bookingId}</Text>
           </div>

           <div className="detail-item">
             <Text strong>Email:</Text>
             <Text>{bookingDetails.email}</Text>
           </div>

           <div className="detail-item">
             <Text strong>Class Date & Time:</Text>
             <Text>{formatDateTime(bookingDetails.dateTime)}</Text>
           </div>
         </div>
         <div className="booking-confirmation-section">
           <div className="booking-confirmation-group">
             <Text>Add to your calander</Text>
             <Space className="calendar-icons">
               <Button 
                 type="primary"
                 icon={<GoogleOutlined />}
                 onClick={() => window.open(getGoogleCalendarUrl(bookingDetails), '_blank')}
               />
               <Button 
                 type="primary"
                 icon={<WindowsOutlined />}
                 onClick={() => window.open(getOutlookCalendarUrl(bookingDetails), '_blank')}
               />
             </Space>
           </div>
         </div>

         <Button 
           type="primary"
           block
           className="book-another-btn"
           onClick={() => navigate('/booking')}
         >
           Book Another Class
         </Button>
       </Card>
     );
   };

   export default Confirmation;

   const getGoogleCalendarUrl = (details) => {
     try {
       const startTime = new Date(details.dateTime);
       const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // 1 hour duration

       const event = {
         text: "Mani's DanceHall - Dance Class",
         details: `Your dance class with Mani's DanceHall\nMeeting Link: ${details.googleMeetLink}`,
         location: 'Online via Google Meet',
         dates: `${startTime.toISOString().replace(/[-:.]/g, '')}/${endTime.toISOString().replace(/[-:.]/g, '')}`
       };

       const googleUrl = new URL('https://calendar.google.com/calendar/render');
       googleUrl.searchParams.append('action', 'TEMPLATE');
       googleUrl.searchParams.append('text', event.text);
       googleUrl.searchParams.append('details', event.details);
       googleUrl.searchParams.append('location', event.location);
       googleUrl.searchParams.append('dates', event.dates);

       return googleUrl.toString();
     } catch (error) {
       console.error('Error generating Google calendar URL:', error);
       return '#';
     }
   };

   const getOutlookCalendarUrl = (details) => {
     try {
       const startTime = new Date(details.dateTime);
       const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // 1 hour duration

       const event = {
         subject: "Mani's DanceHall - Dance Class",
         description: `Your dance class with Mani's DanceHall\nMeeting Link: ${details.googleMeetLink}`,
         location: 'Online via Google Meet',
         startTime: startTime.toISOString(),
         endTime: endTime.toISOString()
       };

       const outlookUrl = new URL('https://outlook.live.com/calendar/0/deeplink/compose');
       outlookUrl.searchParams.append('subject', event.subject);
       outlookUrl.searchParams.append('body', event.description);
       outlookUrl.searchParams.append('location', event.location);
       outlookUrl.searchParams.append('startdt', event.startTime);
       outlookUrl.searchParams.append('enddt', event.endTime);
       outlookUrl.searchParams.append('path', '/calendar/action/compose');
       outlookUrl.searchParams.append('rru', 'addevent');

       return outlookUrl.toString();
     } catch (error) {
       console.error('Error generating Outlook calendar URL:', error);
       return '#';
     }
   };