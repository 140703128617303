// teacher.js
import React, { useEffect, useState, useCallback } from 'react';
import { LaptopOutlined, NotificationOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, Table, Tabs } from 'antd';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

 // Similar logic to format date and time as done in booking.js
 function formatDate(date) {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}


const { Header, Content, Footer, Sider } = Layout;

const Teacher = () => {
  const { logout } = useAuth0();
  const [activeSection, setActiveSection] = useState('dashboard');
  const [activeClasses, setActiveClasses] = useState([]);
  const [activeClassesDetails, setActiveClassesDetails] = useState([]);
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(true);

  // Sidebar items
  const sidebarItems = [
    { key: '1', label: 'Dashboard', section: 'dashboard' },
    { key: '2', label: 'My Classes', section: 'bookings' },
    { key: '3', label: 'Settings', section: 'settings' },
    { key: '4', label: 'Payments', section: 'payments' },
    { key: '5', label: 'Feedback', section: 'feedback' },
    { key: '6', label: 'Help', section: 'help' },
    { key: '7', label: 'Logout', icon: <LogoutOutlined />, onClick: () => logout({ returnTo: window.location.origin }) },
  ];

  const menuItems = sidebarItems.map((item) => ({
    key: item.key,
    label: item.label,
    icon: item.icon,
    onClick: item.onClick ? item.onClick : () => setActiveSection(item.section),
  }));

  // const breadcrumbItems = [
  //   { title: 'Home' },
  //   { title: 'Teacher' },
  //   { title: activeSection === 'dashboard' ? 'Dashboard' : 'Bookings' },
  // ];

  const loadData = useCallback(async () => {
    try {
        setLoading(true);
        // Fetch bookings data
        const bookingsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/bookings`);
        const bookingsData = await bookingsResponse.json();
        console.log('Bookings data:', bookingsData);

        // Fetch active classes data
        const activeClassesResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/active-classes`);
        const activeClassesData = await activeClassesResponse.json();
        console.log('Active classes data:', activeClassesData);

        if (bookingsData && activeClassesData) {
            // Create a map of active classes details using _id as key
            const activeClassesMap = activeClassesData.reduce((acc, curr) => {
                acc[curr._id] = curr;
                return acc;
            }, {});

            // Combine the data - using bookingsData directly
            const combinedData = bookingsData.map(booking => ({
                ...booking,
                studentName: activeClassesMap[booking._id]?.studentName || '',
                studentEmail: activeClassesMap[booking._id]?.studentEmail || ''
            }));

            console.log('Combined Data:', combinedData); // Debug log
            setActiveClasses(combinedData);
        }
    } catch (err) {
        console.error('Error loading data:', err);
        setActiveClasses([]);
    } finally {
        setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const cancelBooking = async (id) => {
    // Implement cancel booking logic here
  };

  const rescheduleBooking = async (id) => {
    // Implement reschedule booking logic here
  };

  const handleEventAdd = async (info) => {
    const title = 'Class Available';
    const endDate = new Date(info.startStr);
    endDate.setHours(endDate.getHours() + 1); // Set duration to 1 hour


    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/addSlot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                dateTime: info.startStr, // Send as ISO string
                currency: 'INR', // or 'USD' based on your logic
            })
        });

        if (response.ok) {
            const slot = await response.json();
            console.log("New slot added:", slot);
            info.view.calendar.addEvent({
                id: slot._id,
                title: title,
                start: new Date(slot.dateTime),
                end: new Date(new Date(slot.dateTime).getTime() + 60 * 60 * 1000),
                allDay: false,
            });
            alert('Slot added successfully');
        } else {
            const errorText = await response.text();
            console.error('Failed to add slot:', errorText);
            alert('Failed to add slot: ' + errorText);
        }
    } catch (error) {
        console.error('Error adding slot:', error);
        alert('Failed to add slot: ' + error.message);
    }
};

  const handleEventClick = async (clickInfo) => {
    const eventId = clickInfo.event.id;
    if (!eventId) {
        console.error('Event ID is undefined');
        alert('Failed to delete slot: Event ID is undefined');
        return;
    }

    console.log("Deleting slot with ID:", eventId);
    if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'?`)) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/slots/${eventId}`, { method: 'DELETE' });
            if (response.ok) {
                clickInfo.event.remove();
                alert('Slot deleted successfully');
            } else {
                alert('Failed to delete slot');
            }
        } catch (error) {
            console.error('Error deleting slot:', error);
            alert('Failed to delete slot');
        }
    }
};


  // Column configuration for the bookings table
  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date & Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
      render: (dateTime) => (
        <div>{moment(dateTime).format('hh:mm A, MMM DD, YYYY')}</div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
    },
    {
      title: 'Booking Status',
      dataIndex: 'bookingStatus',
      key: 'bookingStatus',
    },
    // { title: 'Student Email', dataIndex: 'email', key: 'email' },
    {
      title: 'Google Meet Link',
      dataIndex: 'googleMeetLink',
      key: 'link',
      render: (link) => <a href={link} target="_blank" rel="noopener noreferrer">Join</a>,
    },
  ];

  const data = activeClasses?.map(activeClass => {
    console.log('Mapping activeClass:', activeClass); // Debug log
    return {
        key: activeClass._id,
        name: activeClass.studentName,
        email: activeClass.studentEmail,
        dateTime: activeClass.slotId?.dateTime,
        googleMeetLink: activeClass.googleMeetLink,
        paymentAmount: activeClass.paymentAmount,
        paymentStatus: activeClass.paymentStatus,
        bookingStatus: activeClass.bookingStatus,
        currency: activeClass.currency
    };
  }) || [];
  console.log('Mapped Data:', data);
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ color: 'white', fontSize: '1.5em', fontWeight: 'bold' }}>My Dance Hall</div>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} style={{ flex: 1, minWidth: 0 }} />
      </Header>
      
      <Content style={{ padding: '24px' }}>
        <Layout style={{ 
          background: '#fff', 
          borderRadius: '8px',
          overflow: 'hidden'
        }}>
          <Sider 
            width={200} 
            style={{ 
              background: '#fff',
              borderRight: '1px solid #f0f0f0',
              height: '100%'
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              style={{ 
                height: '100%',
                borderRight: 0
              }}
              items={menuItems}
            />
          </Sider>
          
          <Content style={{ padding: '24px' }}>
            {activeSection === 'dashboard' && (
              <div style={{ height: 'calc(100vh - 184px)' }}>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
                  initialView="timeGridWeek"
                  selectable={true}
                  editable={true}
                  height="100%"
                  events={async (fetchInfo, successCallback, failureCallback) => {
                    try {
                      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/slots`);
                      const slots = await response.json();
                      const events = slots.map((slot) => ({
                        title: 'Available Slot',
                        start: new Date(slot.dateTime),
                        end: new Date(new Date(slot.dateTime).getTime() + 60 * 60 * 1000),
                        id: slot._id,
                      }));
                      successCallback(events);
                    } catch (error) {
                      failureCallback(error);
                    }
                  }}
                  select={handleEventAdd}
                  eventClick={handleEventClick}
                />
              </div>
            )}

            {activeSection === 'bookings' && (
              <div style={{ height: 'calc(100vh - 184px)', display: 'flex', flexDirection: 'column' }}>
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Active Classes" key="1">
                    <Table 
                      columns={columns} 
                      dataSource={data.filter(item => moment(item.dateTime).isAfter(moment()))}
                      rowKey="key" 
                      pagination={{ 
                        pageSize: 10,
                        showTotal: (total) => `Total ${total} active classes`
                      }}
                      scroll={{ y: 'calc(100vh - 280px)' }}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Past Classes" key="2">
                    <Table 
                      columns={columns} 
                      dataSource={data.filter(item => moment(item.dateTime).isSameOrBefore(moment()))}
                      rowKey="key" 
                      pagination={{ 
                        pageSize: 10,
                        showTotal: (total) => `Total ${total} past classes`
                      }}
                      scroll={{ y: 'calc(100vh - 280px)' }}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            )}
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: 'center' }}>My Dance Hall ©{new Date().getFullYear()}</Footer>
    </Layout>
  );
};

export default Teacher;