import React from 'react';
import { Layout, Typography, Row, Col, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;

const PrivacyPolicy = () => {
  const sections = [
    {
      title: 'Introduction',
      content: `This Privacy Policy ("Policy") is an integral part of the Terms and Conditions of the Website. By accessing or using our services, you agree to this Policy. If you have any questions, please contact us prior to using the services.`
    },
    {
      title: '1. User Information',
      content: 'To provide the best possible service, we collect the following types of information:',
      subsections: [
        {
          title: '1.1 Registration Information',
          content: 'When you register with the Website, you provide details such as name, contact information, and payment information as necessary.'
        },
        {
          title: '1.2 Cookies and Tracking Technologies',
          content: 'The Website uses cookies to enhance your experience, remember preferences, and monitor trends. You can disable cookies in your browser settings, though this may limit available features.'
        },
        {
          title: '1.3 Log Data',
          content: 'Our servers record information your browser sends automatically, including IP address, browser type, browser language, access times, and one or more cookies that uniquely identify your browser.'
        },
        {
          title: '1.4 Communications',
          content: 'We may retain records of communications sent to us (e.g., support inquiries) to maintain a complete service history.'
        },
        {
          title: '1.5 Usage Monitoring',
          content: 'In certain cases, we may monitor messages between users of the Website for quality assurance, training, or investigation of reported behavior that violates our Terms and Conditions.'
        },
        {
          title: '1.6 Associated Sites and Third-Party Integrations',
          content: 'Our services may interact with other websites or third-party applications. Information shared with those sites is processed according to this Policy.'
        }
      ]
    },
    {
      title: '2. User Choice and Consent',
      content: 'Upon registration, you provide your information voluntarily. Should we intend to use your information for purposes outside of those for which it was collected, we will seek your consent, which you may decline.'
    },
    {
      title: '3. Sharing of Personal Information',
      content: 'We share your information only under specific circumstances:',
      subsections: [
        {
          title: '3.1 With Your Consent',
          content: 'We require users to opt-in for sharing any sensitive personal data as per this Policy.'
        },
        {
          title: '3.2 Legal and Compliance',
          content: `We may disclose your information if we believe it's necessary to:
          • Comply with applicable laws, regulations, or legal requests.
          • Enforce our Terms and Conditions, including investigations into potential violations.
          • Address fraud, security, or technical issues.
          • Protect the rights, property, or safety of the Website, its users, or the public.`
        },
        {
          title: '3.3 Business Transfers',
          content: 'In case of a merger, acquisition, or asset sale, we will notify you if your information will be transferred and is subject to a different privacy policy.'
        },
        {
          title: '3.4 Aggregated Data',
          content: 'We may share anonymized, aggregated data (e.g., user statistics, usage trends) with third parties for analysis or service improvement. This data does not identify individual users.'
        }
      ]
    },
    {
      title: '4. Security and Data Protection',
      content: 'We take data security seriously and use standard measures to protect your information. However, no method of transmission or storage is entirely secure, so while we strive to protect your data, we cannot guarantee absolute security.'
    },
    {
      title: '5. User Rights',
      content: 'Depending on your location, you may have rights over your data, including the right to access, correct, delete, or restrict its use. Please contact us to exercise these rights.'
    },
    {
      title: '6. Changes to the Policy',
      content: 'This Policy may be updated periodically. If we make any significant changes, we will notify users via a prominent message on the website or by email where possible.'
    },
    {
      title: 'Contact Us',
      content: 'If you have questions about this Policy or our data practices, please contact us at support@dancehall.com'
    }
  ];

  return (
    <Layout>
      <Content style={{ 
        background: 'linear-gradient(180deg, #E1F0FF 0%, #FFFFFF 100%)',
        minHeight: '100vh',
        padding: '24px'
      }}>
        <Row justify="center">
          <Col xs={24} sm={24} md={20} lg={16} style={{ 
            background: 'white',
            padding: '32px',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
          }}>
            <Link to="/" style={{ 
              display: 'inline-flex', 
              alignItems: 'center',
              marginBottom: '24px',
              color: '#1890ff'
            }}>
              <ArrowLeftOutlined style={{ marginRight: '8px' }} />
              Back to Home
            </Link>

            <Title level={1} style={{ 
              fontSize: '28px',
              marginBottom: '32px',
              textAlign: 'center'
            }}>
              Privacy Policy
            </Title>

            {sections.map((section, index) => (
              <div key={index} style={{ marginBottom: '32px' }}>
                <Title level={2} style={{ fontSize: '20px' }}>{section.title}</Title>
                <Paragraph style={{ marginBottom: '16px', whiteSpace: 'pre-line' }}>
                  {section.content}
                </Paragraph>
                
                {section.subsections && section.subsections.map((subsection, subIndex) => (
                  <div key={subIndex} style={{ marginLeft: '24px', marginBottom: '16px' }}>
                    <Title level={3} style={{ fontSize: '16px' }}>{subsection.title}</Title>
                    <Paragraph style={{ whiteSpace: 'pre-line' }}>
                      {subsection.content}
                    </Paragraph>
                  </div>
                ))}
              </div>
            ))}

            <Divider />

            <Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
              Last Updated: {new Date().toLocaleDateString('en-US', { 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric' 
              })}
            </Text>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default PrivacyPolicy; 